
import { defineComponent, ref, watchEffect, reactive } from "vue";
import { Loading } from "@/components";
import { Tag3 } from "@/uikit";
import { FARMING_DIARY_FRAGMENT } from "@/graphql";
import { TDiary } from "@/interface";
import { format } from "date-fns";
import jwtDecode from "jwt-decode";
import { useRoute, useGetAll, useGetOne } from "@/helpers";
import { get, cloneDeep } from "lodash";

export default defineComponent({
  components: { Tag3 },
  setup() {
    const farmingDiaryId = ref<string>();
    const visiblePhases = ref<string[]>([]);

    const { query, push } = useRoute();
    const token = localStorage.getItem("x-token");
    const res = jwtDecode(String(token));

    const _farmingDiaryId = get(res, "farmingDiaryId", "");
    if (!_farmingDiaryId) push("./no-data-result");
    farmingDiaryId.value = _farmingDiaryId;

    const _visiblePhases: any[] = get(res, "visiblePhases", []);
    visiblePhases.value = _visiblePhases.map(x => x.phaseId);

    const checkVisiblePhases = (id: string) => {
      return visiblePhases.value.includes(id);
    };

    const diaryItem = ref();
    const { loading, data } = useGetOne<TDiary>(
      "FarmingDiary",
      FARMING_DIARY_FRAGMENT,
      { id: farmingDiaryId.value }
    );

    watchEffect(() => {
      if (data.value) {
        diaryItem.value = data.value;
      }
    });

    // TODO
    const handleDetail = (id: string) => {
      console.log("handleDetail -> id", id);
      push(`/diary/${id}`);
    };

    return { loading, diaryItem, format, checkVisiblePhases, handleDetail };
  }
});
